import React from 'react';
import PropTypes from 'prop-types';
import ApplyCoupon from './applyCoupon';

const Coupon = ({ urlCoupon }) => {
  return <ApplyCoupon urlCoupon={urlCoupon} />;
};

Coupon.defaultProps = {
  urlCoupon: null
};

Coupon.propTypes = {
  urlCoupon: PropTypes.string
};

export default Coupon;

/* eslint-disable react/prop-types */
import React from 'react';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form, Field, Formik } from 'formik';
import Loader from '../ui/loader';
import Style from './coupon.module.scss';
import COPY from '../copy';
import {
  APPLY_STRIPE_COUPON,
  SAVE_COUPON,
  GET_COUPON,
  CLEAR_COUPON
} from '../../graphql/coupon/schema';

let couponValid = false;

const ApplyCoupon = ({ urlCoupon }) => {
  const [ApplyStripeCoupon] = useMutation(APPLY_STRIPE_COUPON);
  const [saveCoupon] = useMutation(SAVE_COUPON);
  const [clearCoupon] = useMutation(CLEAR_COUPON);

  const { data } = useQuery(GET_COUPON);
  const { Coupon } = data;
  const { promotion_accepted } = Coupon.metadata;

  return (
    <Formik
      initialValues={{ coupon: urlCoupon || '' }}
      enableReinitialize
      onSubmit={async ({ coupon }, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        const cleanCoupon = coupon.trim().toLowerCase();
        localStorage.setItem('tiu_coupon', cleanCoupon);

        // get coupon information from Stripe
        const ApplyStripeCouponData = await ApplyStripeCoupon({
          variables: {
            coupon: cleanCoupon
          }
        });

        const {
          status,
          message
        } = ApplyStripeCouponData.data.applyStripeCoupon.responses[0];

        // invalid coupon
        if (status === 204) setFieldError('coupon', message);

        // valid coupon
        if (status === 200) {
          couponValid = true;

          // save coupon data in cache
          const {
            amount_off,
            duration_in_months,
            duration,
            metadata,
            percent_off
          } = ApplyStripeCouponData.data.applyStripeCoupon;

          // Add coupon info to global state
          await saveCoupon({
            variables: {
              amount_off,
              coupon: cleanCoupon,
              coupon_applied: true,
              duration,
              duration_in_months,
              metadata,
              percent_off: percent_off * 0.01
            }
          });
        }
      }}
    >
      {({ values, ...formikBag }) => {
        const { coupon } = values;

        return (
          <>
            <div className={Style.formWrapper}>
              {couponValid ? (
                <>
                  <h4>{promotion_accepted || COPY.coupon.applied.headline}</h4>
                </>
              ) : (
                <>
                  <h4>{COPY.coupon.notApplied.headline}</h4>
                  <p>{COPY.coupon.notApplied.copy}</p>
                </>
              )}

              <Form onSubmit={formikBag.handleSubmit}>
                <div className="input-container">
                  <Field
                    disabled={couponValid}
                    value={coupon}
                    placeholder="Promo Code"
                    type="text"
                    name="coupon"
                    style={{ fontWeight: coupon ? 'bold' : 'normal' }}
                    data-test="input-coupon"
                  />

                  {formikBag.isSubmitting && <Loader />}

                  {formikBag.errors.coupon && (
                    <p className="tiu-form-error error-message">
                      {formikBag.errors.coupon}
                    </p>
                  )}
                  {couponValid && (
                    <p className="text-teal-tone-3 text-left">
                      <small>{COPY.coupon.applied.success}</small>
                    </p>
                  )}
                </div>

                <button
                  className="button primary expanded mobile-only"
                  type="submit"
                  disabled={formikBag.isSubmitting}
                >
                  Apply
                </button>
                <button
                  className={Style.removeCoupon}
                  type="button"
                  onClick={async () => {
                    clearCoupon();
                    localStorage.removeItem('tiu_coupon');
                    couponValid = false;
                    formikBag.resetForm();
                    if (urlCoupon) {
                      navigate('/pick-plan');
                    }
                  }}
                >
                  &times;
                </button>
              </Form>
            </div>

            {couponValid && (
              <div className={`${Style.successState} fade-up-in-100`}>
                <div>
                  <p>{COPY.coupon.applied.tagline}</p>
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default ApplyCoupon;
